<template>
  <div class="flex flex-col gap-8 pb-16 md:pt-8">
    <PropertyGallery :item="property" full-size class="md:mx-10" :is-mobile />
    <div class="flex flex-col gap-8 px-4 md:px-10">
      <div class="flex flex-col items-start md:flex-row md:gap-6">
        <div class="min-h-[232px] w-full rounded-t-2xl bg-white px-3 py-4 md:w-7/12 md:rounded-2xl md:px-8 md:py-6">
          <span class="text-body first-line:text-subhead-1 inline-block whitespace-pre-wrap">{{ description }}</span>
        </div>
        <hr class="w-full border-b border-solid border-black-10 px-3 md:hidden" />
        <PropertyAgentCard :user="user" :is-mobile class="w-full md:w-5/12" />
      </div>
      <PropertyTabsGeneralInfo class="rounded-2xl bg-white" :item="property" :is-mobile />
      <PropertyTabsLocation class="rounded-3xl bg-white" :item="property" :is-mobile />
      <PropertyTabsPrice
        v-if="useGetPropertyPaymentPlans(property.payment_plans)?.[0]?.length"
        class="md:rounded-3xl md:bg-white"
        :item="property"
        show-commission
        :is-mobile
        @open-units="scrollToUnits"
      />
      <div id="units" ref="unitsRef">
        <PropertyTabsUnits
          v-if="getPropertyUnits(property.apartments || [])?.length"
          class="rounded-3xl bg-white"
          :item="property"
          :is-mobile
        />
      </div>
      <div class="flex flex-col-reverse items-start gap-8 rounded-3xl md:flex-row md:bg-white md:p-8">
        <PropertyAgentCard :user="user" :is-mobile class="w-full rounded-3xl border-dm-50 md:w-5/12 md:border" />
        <div class="flex w-full items-center gap-6 rounded-2xl bg-dm-80 p-3 md:w-7/12 md:p-8">
          <div class="flex-1 rounded-lg bg-white px-3 py-4 md:p-6">
            <h4 class="text-subhead-3 md:text-h4 mb-1">{{ property.title.en }}</h4>
            <p class="text-caption-2 md:text-body-2 mb-3 text-black-60 md:mb-2">{{ property.address }}</p>
            <p class="text-caption md:text-subhead-2 mb-2 text-black-90">
              {{ isNotFinished ? 'Project is expected to be finished in' : 'Project was finished in' }}
              <span v-if="property.planned_completion_at" class="text-primary-100">{{
                format(new Date(property.planned_completion_at), 'QQQ yyyy')
              }}</span>
            </p>
            <div class="relative h-2 flex-1 overflow-hidden rounded-md bg-black-05">
              <p class="absolute h-full rounded-md bg-primary-100" :style="{ width: progressPercentage + '%' }"></p>
            </div>
            <p class="text-caption-2 mt-1 text-black-90">{{ progressPercentage }}% completed</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { format, isFuture } from 'date-fns'
import type { PropertyTypes, User } from '@/types'

type Props = {
  property: PropertyTypes.PropertyDetailed
  user: User
  description: string
}
const props = defineProps<Props>()

const { smaller } = useBreakpoints(useGetBreakpoints())
const isMobile = smaller('xs')

const unitsRef = ref<HTMLElement>()

const progressPercentage = computed(() => {
  if (props.property.construction_progress.length) {
    return Number(props.property.construction_progress).toFixed(0)
  }
  return 0
})

const isNotFinished = computed(() => {
  if (!props.property.planned_completion_at) return false
  return isFuture(new Date(props.property.planned_completion_at))
})

const scrollToUnits = () => {
  unitsRef.value!.scrollIntoView({ behavior: 'smooth' })
}
</script>

<style scoped></style>
